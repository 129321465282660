import axios from "axios";
import { API_BASE_URL } from "@/modules/api-base-url";

export default {
  namespaced: true,
  state: {
    service: [],
  },
  getters: {
    getService: (state) => state.service,
  },
  mutations: {
    SET_SERVICE: (state, data) => {
      state.service = data;
    },
  },
  actions: {
    async fetchService({ commit }) {
      const response = await axios.get(
        API_BASE_URL + "/mit-backend/settings/get-service.php"
      );

      commit("SET_SERVICE", response.data);
    },
  },
};
