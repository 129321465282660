<template>
  <div class="calendar">
    <div class="calendar__header">
      <button @click="prev" class="calendar__prev-button">
        <img src="@/assets/images/icons/left-arrow.svg" alt="icon" />
      </button>
      <div class="calendar__date">{{ monthsArr[mounth] }} {{ year }}</div>
      <button @click="next" class="calendar__next-button">
        <img src="@/assets/images/icons/right-arrow.svg" alt="icon" />
      </button>
    </div>
    <ul class="calendar__weeks">
      <li v-for="day in weekArray" :key="day" class="calendar-week-day">
        {{ day }}
      </li>
    </ul>
    <ul class="calendar__body">
      <li
        class="calendar__day"
        @click="eventHandle(day)"
        v-for="(day, index) in getCalendarDay"
        :key="index"
        :class="{
          'calendar-day-active': todayDate === day.number,
          'calendar-day-selected': selectedDayValue(day.number),
          'calendar-day-reserved': day.reserved,
          'calendar-day-event': day.event && !day.reserved,
        }"
      >
        <div class="calendar__day-button">
          <span class="calendar__day-value">{{ day.number }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { months } from "@/modules/variables";

export default {
  data() {
    return {
      mounth: null,
      year: null,
      actualMounth: null,
      actualYear: null,
      todayDay: null,
      selectedDay: false,
      monthsArr: months,
      weekArray: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Нд"],
      selectedDate: {},
    };
  },

  mounted() {
    const currentDate = new Date();
    this.year = currentDate.getFullYear();
    this.mounth = currentDate.getMonth();
    this.actualMounth = currentDate.getMonth();
    this.actualYear = currentDate.getFullYear();
    this.todayDay = currentDate.getDate();

    this.selectedDate.mounth = currentDate.getMonth();
    this.selectedDate.year = currentDate.getFullYear();

    this.$emit("calendarEvent", {
      day: this.todayDay,
      selectedDay: null,
      mounth: this.mounth,
      year: this.year,
      showDate: false,
    });

    this.getData();
  },
  computed: {
    ...mapGetters("calendar", ["getCalendarDay"]),
    todayDate() {
      const currentDate = new Date();
      let today = currentDate.getDate();
      if (
        this.mounth != currentDate.getMonth() ||
        this.year != currentDate.getFullYear()
      ) {
        today = false;
      }

      return today;
    },

    selectedDayValue() {
      return (number) => {
        return (
          this.selectedDay === number &&
          this.mounth === this.selectedDate.mounth &&
          this.year === this.selectedDate.year
        );
      };
    },
  },
  methods: {
    ...mapMutations("calendar", ["SET_CALENDAR_DATA"]),

    eventHandle(eventData) {
      this.selectedDay = eventData.number;

      this.selectedDate.mounth = this.mounth;
      this.selectedDate.year = this.year;

      this.$emit("calendarEvent", {
        day: eventData.number,
        mounth: this.mounth,
        selectedDay: this.selectedDay,
        year: this.year,
        showDate: true,
        reserved: eventData.reserved,
      });
    },

    prev() {
      this.mounth--;

      if (this.mounth < 0) {
        this.mounth = 11;
        this.year--;
      }

      this.getData();
    },
    next() {
      this.mounth++;

      if (this.mounth > 11) {
        this.mounth = 0;
        this.year++;
      }

      this.getData();
    },

    getData() {
      const newDate = new Date(this.year, this.mounth, 1);
      this.init(newDate);

      this.$emit("calendarEvent", {
        day: this.todayDay,
        mounth: this.mounth,
        year: this.year,
        showDate: false,
      });
    },
    init(currentDate) {
      const daysCount = getTotalDaysInAMonth(
        currentDate.getFullYear(),
        currentDate.getMonth()
      );
      const emptyDaysCount = getFirstDayOfWeek(
        currentDate.getFullYear(),
        currentDate.getMonth()
      );
      const quantityDays = emptyDaysCount + daysCount;

      const days = Array.from({ length: quantityDays }, (_, index) => {
        const dayData = {};

        if (index < emptyDaysCount) {
          dayData.number = null;
        } else {
          const number = index - emptyDaysCount + 1;
          dayData.number = number;
        }

        dayData.reserved = false;
        dayData.event = false;

        if (this.year >= this.actualYear) {
          if (this.mounth === this.actualMounth) {
            if (dayData.number < this.todayDay) {
              dayData.oldDay = true;
            }

            if (dayData.number === this.todayDay) {
              dayData.oldDay = true;
            }
          } else if (this.mounth <= this.actualMounth) {
            dayData.oldDay = true;
          }
        } else {
          dayData.oldDay = true;
        }

        return dayData;
      });

      this.SET_CALENDAR_DATA(days);

      function getTotalDaysInAMonth(year, month) {
        return new Date(year, month + 1, 0).getDate();
      }

      function getFirstDayOfWeek(year, month) {
        const firstDayOfMonth = new Date(year, month, 1);

        let emptyDaysCount = firstDayOfMonth.getDay();

        if (emptyDaysCount === 0) {
          emptyDaysCount = 6;
        } else {
          emptyDaysCount -= 1;
        }

        return emptyDaysCount;
      }
    },
  },
};
</script>

<style lang="scss">
.calendar {
  width: 100%;
  // max-width: 500px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.4);
  padding: 20px 20px;

  &__header {
    display: flex;
    // align-items: center;
    justify-content: center;
    column-gap: 2px;
    margin-bottom: 29px;
  }

  &__prev-button {
    padding: 8px 10px 7px 10px;
    border-radius: 12px 0px 0px 12px;
    border: none;
    background: #000;
    cursor: pointer;
  }

  &__next-button {
    padding: 8px 8px 7px 10px;
    border-radius: 0px 12px 12px 0px;
    border: none;
    background: #000;
    cursor: pointer;
  }

  &__date {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    padding: 5px;
    text-align: center;
    background: #f5f5f5;
  }

  &__weeks {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    column-gap: 52px;
    padding-inline-start: 0px;
    font-size: 14px;

    @media screen and (max-width: 560px) {
      column-gap: 12px;
    }
  }

  &__body {
    margin-top: 30px;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    column-gap: 52px;
    row-gap: 29px;
    padding-inline-start: 0px;

    @media screen and (max-width: 560px) {
      column-gap: 12px;
      row-gap: 12px;
    }
  }

  &__day {
    border-radius: 50%;
    cursor: pointer;

    &-button {
      position: relative;
      padding-bottom: 100%;
    }

    &-value {
      position: absolute;
      top: 50%;
      left: 50%;
      font-weight: 500;
      transform: translate(-50%, -50%);
    }
  }
}

.calendar-week-day {
  text-align: center;
  color: #525659;
}

.calendar-month-year-container {
  padding: 10px 10px 20px 10px;
  color: #525659;
  cursor: pointer;
}

.calendar-today-button {
  margin-top: -10px;
  border-radius: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  color: #525659;
  padding: 5px 10px;
}

.calendar-today-button {
  height: 27px;
  margin-right: 10px;
  background-color: #ec7625;
  color: white;
}

.calendar-months,
.calendar-years {
  flex: 1;
  border-radius: 10px;
  height: 30px;
  border: none;
  cursor: pointer;
  outline: none;
  color: #525659;
  font-size: 15px;
}

.calendar-day-event {
  border: 1px solid #078b04;
  color: #078b04;
}

.calendar-day-reserved {
  background: none;
  border: none;
  color: #7a7a7a;
}

.calendar-day-selected {
  border: 1px solid #000;
  border-radius: 50%;
}

.calendar-day-active {
  background-color: #000;
  color: white;
  border-radius: 50%;
}
</style>
