export default {
  namespaced: true,

  state: {
    calendarData: [],
  },
  getters: {
    getCalendarDay: (state) => state.calendarData,
  },
  mutations: {
    SET_CALENDAR_DATA: (state, data) => {
      state.calendarData = data;
    },
    SET_RESERVE_DAYS: (state, data) => {
      state.calendarData.forEach((day, index) => {
        const dataIndex = data.findIndex((item) => item.number === day.number);

        let reservedValue = null;

        data.forEach((item) => {
          if (day.number === item.number && item.reserved === "true") {
            reservedValue = true;
          }
        });

        // if ((index + 1) % 7 === 1 || (index + 1) % 7 === 2) {
        //   reservedValue = true;
        // }

        if (state.calendarData[index].oldDay) {
          state.calendarData[index].reserved = true;
        } else {
          state.calendarData[index].reserved = reservedValue;
        }

        if (dataIndex !== -1) {
          state.calendarData[index].event = true;
        } else {
          state.calendarData[index].event = false;
        }
      });
    },
  },
};
