const defaultData = {
  text: null,
  description: null,
  img: null,
  active: false,
};

export default {
  namespaced: true,

  state: {
    dropdownID: null,
    dropdownData: new Array(3).fill(defaultData),
  },
  getters: {
    getDropdownID: (state) => state.dropdownID,
    getDropdownDataItem: (state) => (index) => {
      return state.dropdownData[index];
    },
  },
  mutations: {
    SET_DROPDOWN_ID: (state, id) => {
      if (state.dropdownID === id) {
        state.dropdownID = null;
      } else {
        state.dropdownID = id;
      }
    },
    CLOSE_DROPDOWN: (state) => {
      state.dropdownID = null;
    },
    SET_DROPDOWN_DATA: (state, data) => {
      state.dropdownData[data.index] = data;
    },
    REMOVE_DROPDOWN_ITEM: (state, index) => {
      state.dropdownData[index] = defaultData;
    },
    RESET_DROPDOWN_DATA: (state) => {
      state.dropdownID = null;
      state.dropdownData = new Array(3).fill(defaultData);
    },
  },
};
