// import { serviceData } from "@/modules/variables";
import axios from "axios";
import { API_BASE_URL } from "@/modules/api-base-url";
import { tarifs } from "@/modules/variables";

export default {
  namespaced: true,

  state: {
    consultant: null,
    consultantID: null,
    date: [],
    baseServiceData: [],
    activeServiceData: [],
    changedServiceaArr: [],
    name: "",
    surname: "",
    last_name: "",
    phone: "",
    email: "",
    coment: "",
    confirm_pravicy: false,
  },
  getters: {
    getUserData: (state) => (group_name) => state[group_name],
    getConsultantID: (state) => state.consultantID,
    getChangedServiceName: (state) =>
      state.changedServiceaArr.map((item) => item.description),
    getTotalPrice: (state) => {
      if (state.date.length) {
        if (state.date.length <= tarifs.length) {
          return tarifs[state.date.length - 1];
        } else {
          return tarifs[tarifs.length - 1];
        }
      }

      return 0;
    },
    getDate: (state) => state.date,
    getServiceData: (state) => state.activeServiceData,
    getChangedServiceArr: (state) => state.changedServiceaArr,
    getConsultant: (state) => state.consultant,
    startRegistration: (sate) => {
      if (sate.consultantID && sate.date.length) {
        return true;
      }

      return false;
    },
  },
  mutations: {
    CHANGE_CLIENT_DATA: (state, data) => {
      state[data.group] = data.value;
    },
    SET_CONSULTANT_ID: (state, data) => {
      state.changedServiceaArr = [];
      if (data !== null) {
        state.consultantID = data.consultant_id;
        state.activeServiceData = data.services;
        state.consultant = data;
      } else {
        state.activeServiceData = state.baseServiceData;
      }
    },
    SET_DATE: (state, data) => {
      state.date = data;
    },
    SET_SERVICE: (state, data) => {
      if (state.consultantID) {
        state.changedServiceItem = data.id;
      }
    },
    TOGLE_CHANGED_SERVICE: (state, data) => {
      const seviceIndex = state.changedServiceaArr.findIndex(
        (item) => item.id === data.id
      );

      if (seviceIndex === -1) {
        state.changedServiceaArr.push(data);
      } else {
        state.changedServiceaArr.splice(seviceIndex, 1);
      }
    },

    REMOVE_CHANGED_SERVICE: (state) => {
      state.changedServiceaArr = [];
    },
    RESET_REGISTRATION_DATA: (state) => {
      state.consultant = null;
      state.consultantID = null;
      state.date = [];
      state.activeServiceData = state.baseServiceData;
      state.changedServiceaArr = [];
      state.name = "";
      state.surname = "";
      state.last_name = "";
      state.phone = "";
      state.email = "";
      state.coment = "";
      state.confirm_pravicy = false;
    },
    SET_REGISTRATION_SERVICE: (state, data) => {
      state.baseServiceData = data;
      state.activeServiceData = data;
    },
  },

  actions: {
    async fetchRegistrationService({ commit }) {
      const response = await axios.get(
        API_BASE_URL + "/mit-backend/settings/get-service.php"
      );

      commit("SET_REGISTRATION_SERVICE", response.data);
    },
  },
};
