import axios from "axios";
import { API_BASE_URL } from "@/modules/api-base-url";

export default {
  namespaced: true,

  state: {
    consultants: [],
  },
  getters: {
    getConsultants: (state) => state.consultants,
    getConsultant: (state) => (consultantID) => {
      const consultant = state.consultants.find(
        (item) => item.consultant_id === consultantID
      );

      return consultant;
    },
  },
  mutations: {
    SET_CONSULTANTS: (state, data) => {
      state.consultants = data;
    },
  },
  actions: {
    async fetchConsultants({ commit }) {
      const phpFileUrl = API_BASE_URL + "/mit-backend/classes.php";
      const textParam = "";

      const response = await axios.get(phpFileUrl, {
        params: {
          text: textParam,
        },
      });

      response.data.consultants.map((consultant) => {
        return (consultant.services = JSON.parse(consultant.services).data);
      });

      commit("SET_CONSULTANTS", response.data.consultants);
    },
  },
};
