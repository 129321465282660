<template>
  <div v-show="getShowSuccessModal" class="success">
    <div class="success__modal">
      <div class="success__img">
        <img src="@/assets/images/icons/sucess.svg" alt="icon" />
      </div>
      <div class="success__text-container">
        <div class="success__title">Вітаємо!</div>
        <div class="success__text">
          Заявка була успішно створена.<br /><br />

          На вказану електронну скриньку булло відправлено нагадування про дату
          і час запису.
        </div>
      </div>
      <div class="success__button">
        <black-button @black-button-event="confirm">Ок</black-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import BlackButton from "../base/BlackButton.vue";

export default {
  components: {
    BlackButton,
  },
  computed: mapGetters("UI", ["getShowSuccessModal"]),
  methods: {
    ...mapMutations("UI", ["SET_SUCCESS"]),
    ...mapMutations("dropdown", ["RESET_DROPDOWN_DATA"]),
    ...mapMutations("registration", ["RESET_REGISTRATION_DATA"]),
    confirm() {
      this.SET_SUCCESS(false);
      this.RESET_DROPDOWN_DATA();
      this.RESET_REGISTRATION_DATA();
    },
  },
};
</script>

<style lang="scss">
.success {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);

  &__modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    max-width: 350px;
    padding: 30px 20px;
    border-radius: 24px;
    background: #fff;
    box-shadow: 0px 18px 88px -4px rgba(24, 39, 75, 0.14),
      0px 8px 28px -6px rgba(24, 39, 75, 0.12);
  }
  &__text {
    text-align: center;
  }

  &__title {
    margin-bottom: 10px;
    text-align: center;
    color: #000;
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
  }
}
</style>
