<template>
  <div class="home">
    <div class="home-container">
      <div class="home__header">
        <img
          class="home__header-img"
          src="@/assets/images/logo.png"
          alt="logo"
        />
      </div>
      <div class="registration-group">
        <main-dropdown
          @dropdownEvent="removeConsultant"
          iconUrl="images/icons/add-consultant.svg"
          :dropdown_id="0"
          >Оберіть приміщення</main-dropdown
        >
        <div
          v-show="getDropdownID === 0"
          class="home-consultants show-content consultants-list"
        >
          <div
            v-for="(consultant, index) in getConsultants"
            :key="index"
            class="home-consultants__item"
          >
            <ConsultantItem
              @add-consultant="updateConsultants"
              :consultantData="consultant"
              :index="index"
            />
          </div>
        </div>
      </div>
      <div id="calendar" class="registration-group">
        <main-dropdown
          @dropdownEvent="removeTime"
          iconUrl="images/icons/time-event.svg"
          :dropdown_id="1"
          >Вкажіть дату та час</main-dropdown
        >
        <div v-show="getDropdownID === 1" class="home-consultants show-content">
          <BaseCalendar @calendarEvent="calendarUpdate" />
          <ChangeTime />
        </div>
      </div>
      <!-- <div class="registration-group">
        <main-dropdown
          @dropdownEvent="removeServsces"
          iconUrl="images/icons/service.svg"
          :dropdown_id="2"
          >Вибір послуги</main-dropdown
        >
        <div v-show="getDropdownID === 2" class="home-consultants show-content">
          <ServiceList />
        </div>
      </div> -->

      <div v-if="startRegistration" class="home__button-container">
        <div class="home__total-price">
          До сплати: <br /><br />
          <span>{{ getTotalPrice }} UAH</span>
        </div>
        <button
          @click="$router.push({ name: 'RegistrationViews' })"
          class="list-button"
        >
          <div class="list-button__text">Продовжити</div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API_BASE_URL } from "@/modules/api-base-url";
import { mapActions, mapMutations, mapGetters } from "vuex";
import MainDropdown from "@/components/home/MainDropdown.vue";
import ConsultantItem from "@/components/home/ConsultantItem.vue";
import BaseCalendar from "@/components/base/BaseCalendar.vue";
import ChangeTime from "@/components/home/ChangeTime.vue";
// import ServiceList from "@/components/home/ServiceList.vue";
// import createBeforeReservedDays from "@/modules/createBeforeReservedDays";

export default {
  name: "HomeView",
  components: {
    MainDropdown,
    ConsultantItem,
    BaseCalendar,
    ChangeTime,
    // ServiceList,
  },
  data() {
    return {
      selectedDate: null,
    };
  },
  mounted() {
    this.fetchRegistrationService();
    this.fetchData();
  },
  computed: {
    ...mapGetters("consultants", ["getConsultants", "getConsultant"]),
    ...mapGetters("registration", [
      "getConsultantID",
      "startRegistration",
      "getTotalPrice",
    ]),
    ...mapGetters("dropdown", ["getDropdownID"]),
  },
  methods: {
    ...mapActions("consultants", ["fetchConsultants"]),
    ...mapActions("registration", ["fetchRegistrationService"]),
    ...mapMutations("registration", [
      "SET_CONSULTANT_ID",
      "SET_DATE",
      "REMOVE_CHANGED_SERVICE",
    ]),
    ...mapMutations("dropdown", ["REMOVE_DROPDOWN_ITEM", "SET_DROPDOWN_DATA"]),
    ...mapMutations("calendar", ["SET_RESERVE_DAYS"]),
    ...mapMutations("timeSelect", ["SET_TIMES_DATA"]),
    removeConsultant(dropdown_id) {
      this.SET_CONSULTANT_ID(null);
      this.REMOVE_DROPDOWN_ITEM(dropdown_id);
      this.SET_RESERVE_DAYS([]);
    },
    removeTime(dropdown_id) {
      this.REMOVE_DROPDOWN_ITEM(dropdown_id);
      this.SET_DATE([]);
    },
    calendarUpdate(data) {
      this.selectedDate = data;
      this.getEvents();
      this.SET_DATE([]);
    },
    removeServsces(dropdown_id) {
      this.REMOVE_DROPDOWN_ITEM(dropdown_id);
      this.REMOVE_CHANGED_SERVICE();
    },
    updateConsultants() {
      this.REMOVE_DROPDOWN_ITEM(1);
      this.REMOVE_DROPDOWN_ITEM(2);

      this.getEvents();
    },
    async fetchData() {
      await this.fetchConsultants();

      if (!this.getConsultantID && this.$route.params.id) {
        const consultant = this.getConsultant(this.$route.params.id);

        if (consultant) {
          this.SET_CONSULTANT_ID(consultant);
          this.SET_DROPDOWN_DATA({
            text: consultant.name,
            description: consultant.description,
            img: consultant.img,
            active: true,
            index: 0,
          });

          this.getEvents();
        }
      }
    },
    async getEvents() {
      if (this.selectedDate && this.getConsultantID) {
        const phpFileUrl = API_BASE_URL + "/mit-backend/search_dates.php";
        const textParam = `${this.getConsultantID}_${
          this.selectedDate.mounth + 1
        }_${this.selectedDate.year}`;

        const response = await axios.get(phpFileUrl, {
          params: {
            reserve_date: textParam,
          },
        });

        if (this.selectedDate.selectedDay) {
          const eventsData = response.data.date_data.map((item) => {
            item.time = JSON.parse(item.time);
            return item;
          });

          const sortVorksDays = eventsData.reduce((arr, item) => {
            if (item.reserved == null) {
              arr.push(item);
            }

            return arr;
          }, []);

          const dayEvent = sortVorksDays.find(
            (item) => item.day === this.selectedDate.selectedDay
          );

          this.SET_TIMES_DATA({ selectedDate: this.selectedDate, dayEvent });
        }

        const reservedDays = response.data.date_data.map((item) => {
          const data = {};
          data.number = Number(item.day);
          data.reserved = item.reserved;

          return data;
        });

        this.SET_RESERVE_DAYS(reservedDays);
      }
    },
  },
};
</script>

<style lang="scss">
.home {
  .home-container {
    display: flex;
    // justify-content: center;
    flex-direction: column;
    row-gap: 30px;
    min-height: 100vh;
    padding: 40px 20px;
  }

  &__header {
    display: flex;
    justify-content: center;
    // margin-bottom: 20px;

    &-img {
      display: block;
      width: 180px;
      overflow: hidden;
    }

    &-link {
      display: block;
      margin-bottom: 12px;
      font-size: 25px;
      font-weight: 700;
      color: #000;
    }

    &-adress {
      color: rgba($color: #000000, $alpha: 0.5);
    }
  }

  .show-content {
    padding-top: 20px;
  }

  &__button-container {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 60px;
  }

  &__total-price {
    font-size: 14px;
    text-align: center;

    span {
      font-size: 24px;
      font-weight: 600;
    }
  }
}
.consultants-list {
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 10px;
}
</style>
