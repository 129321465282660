<template>
  <div class="confirm-modal">
    <div class="confirm-modal__container">
      <div class="confirm-modal__message">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi!
      </div>
      <div class="confirm-modal__buttons">
        <button class="confirm-modal__confirm-button black-button">
          Підтвердити
        </button>
        <button class="confirm-modal__cencel-button black-button">
          Відмінити
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.confirm-modal {
  display: none;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);

  &__container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 320px;
    padding: 24px;
    background-color: #fff;
    border-radius: 10px;
    transform: translate(-50%, -50%);
  }

  &__message {
    margin-bottom: 12px;
    text-align: center;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 12px;
  }

  &__confirm-button {
    width: 50%;
  }

  .confirm-modal__cencel-button {
    width: 50%;
    background: none;
    border: 1px solid #000;
    color: #000;
  }
}
</style>
