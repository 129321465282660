<template>
  <button @click="userClick" class="black-button">
    <slot></slot>
  </button>
</template>

<script>
export default {
  methods: {
    userClick() {
      this.$emit("black-button-event");
    },
  },
};
</script>

<style lang="scss">
.black-button {
  padding: 20px 40px;
  background-color: #000;
  border: none;
  border-radius: 30px;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.3px;
  cursor: pointer;
}
</style>
