import { createStore } from "vuex";
import auth from "./modules/auth.js";
import UI from "./modules/UI.js";
import calendar from "./modules/calendar.js";
import registration from "./modules/registration.js";
import consultants from "./modules/consultants.js";
import dropdown from "./modules/dropdown.js";
import timeSelect from "./modules/timeSelect.js";
import service from "./modules/admin/service.js";

export default createStore({
  modules: {
    auth,
    UI,
    calendar,
    registration,
    consultants,
    dropdown,
    timeSelect,
    service,
  },
});
