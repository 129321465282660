const authData = localStorage.getItem("auth")
  ? JSON.parse(localStorage.getItem("auth"))
  : null;

export default {
  namespaced: true,

  state: {
    id: authData ? authData.id : "",
    user: authData ? authData.user : "",
    token: authData ? authData.token : "",
    userStatus: authData ? authData.userStatus : "",
    email: authData ? authData.email : "",
    consultantId: authData ? authData.consultantId : "",
  },
  getters: {
    getToken: (state) => state.token,
    getUserName: (state) => state.user,
    getAuthValue: (state) => (key) => state[key],
  },
  mutations: {
    SET_TOKEN: (state, data) => {
      state.token = data;
      localStorage.setItem("auth", JSON.stringify(state));
    },

    SET_USER_NAME: (state, data) => {
      state.user = data;
      localStorage.setItem("auth", JSON.stringify(state));
    },

    SET_AUTH_DATA: (state, data) => {
      state.id = Number(data.id);
      state.user = data.user;
      state.token = data.token;
      state.userStatus = data.user_status;
      state.email = data.email;
      state.consultantId = data.consultant_id;

      localStorage.setItem("auth", JSON.stringify(state));
    },

    REMOVE_TOKEN(state) {
      state.token = "";
      state.user = "";

      localStorage.removeItem("auth");
    },
  },
};
