<template>
  <div
    @click="addConsultant"
    class="consultant-item"
    :class="{
      'consultant-item--active':
        getConsultantID === consultantData.consultant_id,
    }"
  >
    <div class="consultant-item__description">
      <div class="consultant-item__description-checked">
        <img
          src="@/assets/images/icons/cheked-consultant.svg"
          alt="ckecked icon"
        />
      </div>
      <div class="consultant-item__name">{{ consultantData.name }}</div>
      <!-- <div class="consultant-item__status">
        {{ consultantData.description }}
      </div> -->
    </div>
    <div class="consultant-item__slider-wrapper">
      <swiper :slides-per-view="1" :space-between="50" :autoHeight="true">
        <swiper-slide
          v-for="imageSrc in consultantData.img.split(',')"
          :key="imageSrc"
        >
          <div class="consultant-item__image">
            <img
              :src="`${api}/mit-backend/uploads/${imageSrc}`"
              alt="consultant"
            />
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API_BASE_URL } from "@/modules/api-base-url";
import { defaultTimes } from "@/modules/variables";
import { mapGetters, mapMutations } from "vuex";
import { monthsRegistration } from "@/modules/variables";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";

// import BlackButton from "../base/BlackButton.vue";

export default {
  props: ["consultantData", "index"],
  components: {
    // BlackButton,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      api: "",
      times: [],
      date: {},
      year: null,
      mounth: null,
      day: null,
      monthsRegData: monthsRegistration,
    };
  },
  mounted() {
    this.api = API_BASE_URL;

    const currentDate = new Date();
    this.year = currentDate.getFullYear();
    this.mounth = currentDate.getMonth();
    this.day = currentDate.getDate();
    const daysCount = new Date(this.year, this.mounth + 1, 0).getDate();

    const phpFileUrl = API_BASE_URL + "/mit-backend/search_dates.php";
    const textParam = `${this.consultantData.consultant_id}_${
      this.mounth + 1
    }_${this.year}`;

    axios
      .get(phpFileUrl, {
        params: {
          reserve_date: textParam,
        },
      })
      .then((response) => {
        const reserveData = response.data.date_data.sort(
          (a, b) => a.day - b.day
        );

        for (let index = this.day + 1; index <= daysCount; index++) {
          let result = reserveData.find((item) => item.day == index);

          if (result && result.reserved == null) {
            const dates = JSON.parse(result.time).data.map(
              (element) => element.time
            );

            const freeDates = defaultTimes.reduce((sortArray, defaultTime) => {
              if (!dates.includes(defaultTime)) {
                sortArray.push(defaultTime);
              }

              return sortArray;
            }, []);

            this.times = freeDates.slice(0, 5);
            this.date.day = result.day;
            this.date.mounth = this.mounth;
            this.date.year = this.year;

            break;
          } else if (!result) {
            this.date.day = index;
            this.date.mounth = this.mounth;
            this.date.year = this.year;
            this.times = defaultTimes.slice(0, 5);
            break;
          }
        }
      });
  },

  computed: {
    ...mapGetters("registration", ["getConsultantID", "getDate"]),

    addTimeClass() {
      return (time) => {
        if (
          this.getConsultantID === this.consultantData.consultant_id &&
          this.getDate.time === time &&
          this.getDate.mounth === this.date.mounth &&
          this.getDate.year === this.date.year
        ) {
          return true;
        }

        return false;
      };
    },
  },

  methods: {
    ...mapMutations("registration", ["SET_CONSULTANT_ID", "SET_DATE"]),
    ...mapMutations("dropdown", [
      "SET_DROPDOWN_DATA",
      "SET_DROPDOWN_ID",
      "CLOSE_DROPDOWN",
    ]),
    resevedDate(data) {
      this.$emit("add-consultant");

      this.date.time = data;
      this.SET_CONSULTANT_ID(this.consultantData);
      this.SET_DATE(this.date);

      this.SET_DROPDOWN_DATA({
        text: this.consultantData.name,
        description: this.consultantData.description,
        img: this.consultantData.img,
        active: true,
        index: 0,
      });

      this.SET_DROPDOWN_DATA({
        text: data,
        description: `${this.date.day} ${
          monthsRegistration[this.getDate.mounth]
        } ${this.getDate.year}`,
        img: null,
        active: true,
        index: 1,
      });

      this.CLOSE_DROPDOWN();
    },
    addConsultant() {
      this.SET_CONSULTANT_ID(this.consultantData);
      this.SET_DATE({});

      this.SET_DROPDOWN_DATA({
        text: this.consultantData.name,
        description: this.consultantData.description,
        img: this.consultantData.img,
        active: true,
        index: 0,
      });

      this.$emit("add-consultant");
    },
    moreDates() {
      this.SET_CONSULTANT_ID(this.consultantData);
      this.SET_DATE({});

      this.SET_DROPDOWN_DATA({
        text: this.consultantData.name,
        description: this.consultantData.description,
        img: this.consultantData.img,
        active: true,
        index: 0,
      });

      this.CLOSE_DROPDOWN();
      this.SET_DROPDOWN_ID(1);
      this.$router.push({ hash: "#calendar" });

      this.$emit("add-consultant");
    },
  },
};
</script>

<style lang="scss">
.consultant-item {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 14px;

  &__slider-wrapper {
    width: 100%;
    height: auto;
    border-radius: 14px;
    overflow: hidden;
  }

  &__image {
    border-radius: 14px;
    overflow: hidden;

    img {
      display: block;
      // width: 100%;
      // height: 100%;
      // object-fit: cover;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
    padding: 20px 15px;
    text-align: center;
    cursor: pointer;

    &-checked {
      display: none;
      position: absolute;
      top: 14px;
      right: 14px;
      width: 22px;
      height: 22px;

      @media screen and (max-width: 560px) {
        width: 18px;
        height: 18px;
      }
    }
  }

  &__status {
    color: #7a7a7a;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
  }

  &__name {
    // margin-bottom: 5px;
    font-size: 22px;
    font-weight: 500;
  }

  &__about {
    font-size: 14px;

    @media screen and (max-width: 560px) {
      font-size: 12px;
    }
  }

  &__time {
    width: 45%;
    padding: 20px 20px 20px 40px;

    @media screen and (max-width: 560px) {
      width: 100%;
      padding: 0;
    }

    &-title {
      margin-bottom: 14px;
      font-size: 14px;

      span {
        font-weight: 500;
      }
    }

    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
      column-gap: 10px;
      row-gap: 20px;
    }

    &-button {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      .black-button {
        padding: 8px 20px;
      }
    }
  }

  &__time-item {
    width: calc(33.333% - 7px);
    padding: 10px;
    background-color: #fff;
    border-radius: 9px;
    cursor: pointer;

    &--active {
      background-color: #000 !important;
      color: #fff;
    }
  }

  &--active {
    background-color: #fff;

    .consultant-item__time-item {
      background-color: #f5f5f5;
    }

    .consultant-item__description-checked {
      display: block;
    }
  }
}
</style>
