const defaultTimes = [
  "9:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18.00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
];

const months = [
  "Січень",
  "Лютий",
  "Березень",
  "Квітень",
  "Травень",
  "Червень",
  "Липень",
  "Серпень",
  "Вересень",
  "Жовтень",
  "Листопад",
  "Грудень",
];

const monthsRegistration = [
  "Січня",
  "Лютого",
  "Березня",
  "Квітня",
  "Травня",
  "Червня",
  "Липня",
  "Серпня",
  "Вересня",
  "Жовтня",
  "Листопада",
  "Грудня",
];

const serviceData = [
  { id: 1, description: "Криптоконсультація", price: 8000 },
  { id: 2, description: "Консультація", price: 6000 },
  { id: 3, description: "Консультація керуючого партнера", price: 12000 },
  { id: 4, description: "Консультація партнера", price: 8000 },
];

const tarifs = [3700, 7000, 10300, 14000, 17500, 21000, 30000];

export { defaultTimes, months, serviceData, monthsRegistration, tarifs };
