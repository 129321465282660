<template>
  <div v-show="getTimeSelectDate.day" class="change-time">
    <div
      v-if="
        (getAmTimes.length || getPmTimes.length) && !getTimeSelectDate.reserved
      "
      class="change-time__content"
    >
      <div class="time-group">
        <div class="time-group__header">Оберіть час початку бронювання</div>
        <div class="time-group__body">
          <div v-if="getAmTimes.length" class="time-group__time-wrapper">
            <div
              v-for="time in getAmTimes"
              :key="time"
              class="time-button"
              @click="start(time)"
              :class="{
                'time-button--active':
                  time === getStartTime.time &&
                  getTimeSelectDate.mounth === getStartTime.mounth &&
                  getTimeSelectDate.day === getStartTime.day &&
                  getTimeSelectDate.year === getStartTime.year,
              }"
            >
              {{ time }}
            </div>
          </div>
          <div v-else class="time-group__message">Відсутні вільні години</div>
        </div>
      </div>
      <div class="time-group">
        <div class="time-group__header">Оберіть час кінця бронювання</div>
        <div class="time-group__body">
          <div v-if="getPmTimes.length" class="time-group__time-wrapper">
            <div
              v-for="time in getPmTimes"
              :key="time"
              class="time-button"
              @click="end(time)"
              :class="{
                'time-button--active':
                  time === getEndTime.time &&
                  getTimeSelectDate.mounth === getEndTime.mounth &&
                  getTimeSelectDate.day === getEndTime.day &&
                  getTimeSelectDate.year === getEndTime.year,
              }"
            >
              {{ time }}
            </div>
          </div>
          <div v-else class="time-group__message">
            Оберіть інший час початку бронювання
          </div>
        </div>
      </div>
    </div>
    <div v-else class="change-time__empty-message">
      На цей день немає вільних місць
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { monthsRegistration } from "@/modules/variables";

export default {
  computed: {
    ...mapGetters("timeSelect", [
      "getAmTimes",
      "getPmTimes",
      "getTimeSelectDate",
      "getStartTime",
      "getEndTime",
      "getRegistrationReserveList",
    ]),
    ...mapGetters("registration", ["getDate"]),
  },
  methods: {
    ...mapMutations("registration", ["SET_DATE"]),
    ...mapMutations("dropdown", ["SET_DROPDOWN_DATA", "CLOSE_DROPDOWN"]),
    ...mapMutations("timeSelect", ["SET_START_TIME", "SET_END_DATA"]),

    start(time) {
      const sentDate = {};
      sentDate.time = time;
      sentDate.day = this.getTimeSelectDate.day;
      sentDate.mounth = this.getTimeSelectDate.mounth;
      sentDate.year = this.getTimeSelectDate.year;

      this.SET_DATE([]);
      this.SET_START_TIME(sentDate);
      this.SET_DROPDOWN_DATA({
        text: time,
        description: `${sentDate.day} ${monthsRegistration[sentDate.mounth]} ${
          sentDate.year
        }`,
        img: null,
        active: true,
        index: 1,
      });

      // this.CLOSE_DROPDOWN();
    },

    end(time) {
      const sentDate = {};
      sentDate.time = time;
      sentDate.day = this.getTimeSelectDate.day;
      sentDate.mounth = this.getTimeSelectDate.mounth;
      sentDate.year = this.getTimeSelectDate.year;

      this.SET_END_DATA(sentDate);

      const reservedList = this.getRegistrationReserveList.map((item) => {
        const data = { ...sentDate };
        data.time = item;
        return data;
      });

      this.SET_DATE(reservedList);
    },
  },
};
</script>

<style lang="scss">
.change-time {
  padding-block: 30px;

  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
  }

  &__empty-message {
    padding: 60px 0 40px;
    color: #d13636;
    text-align: center;
  }
}

.time-group {
  border-radius: 14px;
  border: 1px solid rgba(0, 0, 0, 0.15);

  &__header {
    padding: 20px 10px 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    text-align: center;
  }

  &__body {
    padding: 20px;
  }

  &__time-wrapper {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 12px;
    row-gap: 20px;

    @media screen and (max-width: 560px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__message {
    text-align: center;
    color: #d13636;
  }
}
</style>
