export default {
  namespaced: true,

  state: {
    textInfoModal: "",
    showSuccessModal: false,
  },
  getters: {
    getTextInfoModal: (state) => state.textInfoModal,
    getShowSuccessModal: (state) => state.showSuccessModal,
  },
  mutations: {
    SET_TEXT_INFO: (state, data) => {
      state.textInfoModal = data.text;

      setTimeout(() => {
        state.textInfoModal = "";
      }, data.time);
    },
    SET_SUCCESS: (state, data) => {
      state.showSuccessModal = data;
    },
  },
};
