<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view />
  <InfoModal />
  <ConfirmModal />
  <SuccessModal />
</template>

<script>
import InfoModal from "@/components/modals/InfoModal.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";

export default {
  components: {
    InfoModal,
    ConfirmModal,
    SuccessModal,
  },
};
</script>

<style lang="scss" src="./scss/style.scss"></style>
