<template>
  <div class="main-dropdown">
    <div class="main-dropdown__img">
      <img
        :src="
          getDropdownDataItem(dropdown_id).img ? imgUrl.split(',')[0] : iconUrl
        "
        alt="dropdown icon"
      />
    </div>
    <div @click="SET_DROPDOWN_ID(dropdown_id)" class="main-dropdown__title">
      <div
        v-if="getDropdownDataItem(dropdown_id).text"
        class="main-dropdown__title-content"
      >
        <div class="main-dropdown__title-description">
          {{ getDropdownDataItem(dropdown_id).description }}
        </div>
        <div class="main-dropdown__title-text">
          {{ getDropdownDataItem(dropdown_id).text }}
        </div>
      </div>

      <slot v-else></slot>
    </div>
    <div class="main-dropdown__buttons-container">
      <div
        v-if="getDropdownDataItem(dropdown_id).active"
        @click="delate"
        class="main-dropdown__delate-button"
      >
        <img src="@/assets/images/icons/delate.svg" alt="icon" />
      </div>
      <div
        v-else
        @click="SET_DROPDOWN_ID(dropdown_id)"
        class="main-dropdown__open-button"
        :class="{
          'main-dropdown__open-button--open': getDropdownID === dropdown_id,
        }"
      >
        <img src="@/assets/images/icons/bottom-arrow.svg" alt="icon" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { API_BASE_URL } from "@/modules/api-base-url";

export default {
  props: ["dropdown_id", "iconUrl"],
  computed: {
    ...mapGetters("dropdown", ["getDropdownDataItem", "getDropdownID"]),
    imgUrl() {
      return `${API_BASE_URL}/mit-backend/uploads/${
        this.getDropdownDataItem(this.dropdown_id).img
      }`;
    },
  },
  methods: {
    ...mapMutations("dropdown", ["SET_DROPDOWN_ID"]),
    delate() {
      this.$emit("dropdownEvent", this.dropdown_id);
    },
  },
};
</script>

<style lang="scss">
.main-dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 58px;
  padding: 5px;
  border-radius: 100px;
  box-shadow: 0px 18px 88px -4px rgba(24, 39, 75, 0.14),
    0px 8px 28px -6px rgba(24, 39, 75, 0.12);

  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #f5f5f5;
    overflow: hidden;

    img {
      flex-grow: 0;
      flex-shrink: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    flex: 1;
    text-align: center;
    cursor: pointer;

    &-description {
      color: #7a7a7a;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
    }

    &-text {
      font-weight: 500;
    }
  }

  &__buttons-container {
    flex-grow: 0;
    flex-shrink: 0;
  }

  &__delate-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 12px 12px 20px;
    border-left: 1px solid rgba(200, 200, 200, 0.2);
    cursor: pointer;
  }

  &__open-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 12px 12px 20px;
    border-left: 1px solid rgba(200, 200, 200, 0.2);
    cursor: pointer;

    &--open {
      img {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
