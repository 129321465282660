<template>
  <Transition name="info">
    <div v-if="getTextInfoModal" class="info-modal">
      <div class="container">
        <div class="info-modal__container">{{ getTextInfoModal }}</div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: mapGetters("UI", ["getTextInfoModal"]),
};
</script>

<style lang="scss">
.info-modal {
  z-index: 999;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 50px 0;
  font-size: 24px;
  line-height: 140%;
  color: #ffffff;
  text-align: center;
  background: #000000;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}

.info-enter-active,
.info-leave-active {
  transition: opacity 0.6s ease;
}

.info-enter-from,
.info-leave-to {
  opacity: 0;
}
</style>
