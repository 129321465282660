import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import store from "@/store";

const routes = [
  {
    path: "/:id?",
    name: "home",
    component: HomeView,
    props: true,
  },
  {
    path: "/registration",
    name: "RegistrationViews",
    component: () => import("../views/RegistrationViews.vue"),
    beforeEnter(to, from, next) {
      const { state } = store;
      // const date = state.registration["date"];
      const consultant = state.registration["consultant"];

      if (to.path === "/registration" && consultant === null) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/admin",
    name: "AdminAuth",
    component: () => import("../views/AdminAuth.vue"),
  },
  {
    path: "/admin-panel",
    name: "AdminPanel",
    component: () => import("../views/AdminPanel.vue"),
    beforeEnter(to, from, next) {
      const { state } = store;
      const token = state.auth["token"];

      const pathInt = to.path
        .split("/")
        .findIndex((element) => element === "admin-panel");

      // Перевірка токену, але також дозволяє вже на маршруті AdminPanel
      if (pathInt !== -1 && !token) {
        next("/admin");
      } else {
        next();
      }
    },
    children: [
      {
        path: "edit-consultants",
        name: "EditConsultants",
        components: {
          default: () => import("../views/EditConsultants.vue"),
          "admin-panel": () => import("../views/EditConsultants.vue"),
        },
      },
      {
        path: "edit-events",
        name: "EditEvents",
        components: {
          default: () => import("../views/EditEvents.vue"),
          "admin-panel": () => import("../views/EditEvents.vue"),
        },
      },
      {
        path: "add-consultant",
        name: "AddConsultant",
        components: {
          default: () => import("../views/EditEvents.vue"),
          "admin-panel": () => import("../views/AddConsultant.vue"),
        },
      },
      {
        path: "edit-consultant/:id",
        name: "EditConsultant",
        components: {
          default: () => import("../views/EditConsultant.vue"),
          "admin-panel": () => import("../views/EditConsultant.vue"),
        },
      },
      {
        path: "vacation",
        name: "Vacation",
        components: {
          default: () => import("../views/VacationPage.vue"),
          "admin-panel": () => import("../views/VacationPage.vue"),
        },
      },
      {
        path: "event-details/:id",
        name: "EventDetails",
        components: {
          default: () => import("../views/EditConsultant.vue"),
          "admin-panel": () => import("../views/EventDetails.vue"),
        },
      },
      {
        path: "service-management",
        name: "ServiceManagement",
        components: {
          default: () => import("../views/Settings/ServiceManagement.vue"),
          "admin-panel": () =>
            import("../views/Settings/ServiceManagement.vue"),
        },
      },
      {
        path: "account-settings",
        name: "AccountSettings",
        components: {
          default: () => import("../views/Settings/AccountSettings.vue"),
          "admin-panel": () => import("../views/Settings/AccountSettings.vue"),
        },
      },
      {
        path: "roles-management",
        name: "RolesManagement",
        components: {
          default: () => import("../views/Settings/RolesManagement.vue"),
          "admin-panel": () => import("../views/Settings/RolesManagement.vue"),
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    } else {
      return { left: 0, top: 0 };
    }
  },
});

// const router = createRouter({
//   // history: createWebHistory(),
//   history: createWebHashHistory(process.env.BASE_URL),
//   routes,
//   scrollBehavior(to) {
//     if (to.hash) {
//       return {
//         el: to.hash,
//         behavior: "smooth",
//       };
//     } else {
//       return { left: 0, top: 0 };
//     }
//   },
// });

export default router;
