import { defaultTimes } from "@/modules/variables";

const sortArrs = (baseArr, sortArr) => {
  const sorted = baseArr.reduce((sortArray, defaultTime) => {
    if (!sortArr.includes(defaultTime)) {
      sortArray.push(defaultTime);
    }

    return sortArray;
  }, []);

  return sorted;
};

const sortStartTimeList = (baseArr, sortArr) => {
  // let prevItemIndex = null;

  const sorted = baseArr.reduce((sortArray, defaultTime) => {
    const data = {};
    data.time = defaultTime;
    data.free = !sortArr.includes(defaultTime);

    // if (!data.free && sortArray[index - 1]) {
    //   // prevItemIndex = index + 1;
    //   sortArray[index - 1].free = false;
    // }

    // if (prevItemIndex === index && addClinTime) {
    //   data.free = false;
    // }

    sortArray.push(data);

    return sortArray;
  }, []);

  return sorted;
};

export default {
  namespaced: true,

  state: {
    startTimeList: [],
    startBufferList: [],
    pmData: [],
    date: {
      day: null,
      month: null,
      year: null,
    },
    orderTime: [],
    startTime: {},
    endTime: {},
    selectedTime: [],
  },
  getters: {
    getAmTimes: (state) => {
      const timeArr = [];

      state.startTimeList.forEach((item) => {
        if (item.free && item.time !== defaultTimes[defaultTimes.length - 1]) {
          timeArr.push(item.time);
        }
      });

      return timeArr;
    },
    getPmTimes: (state) => state.pmData,
    getStartTime: (state) => state.startTime,
    getEndTime: (state) => state.endTime,
    getTimeSelectDate: (state) => state.date,
    getRegistrationReserveList: (state) => {
      const firstIndex = defaultTimes.findIndex(
        (item) => item === state.startTime.time
      );

      const lastIndex = defaultTimes.findIndex(
        (item) => item === state.endTime.time
      );

      const arr = defaultTimes.slice(firstIndex, lastIndex);

      return arr;
    },
  },
  mutations: {
    SET_TIMES_DATA: (state, data) => {
      state.date = data.selectedDate;
      state.startTime = {};
      state.endTime = {};

      if (data.dayEvent) {
        const reservedTimes = data.dayEvent.time.data.map(
          (element) => element.time
        );

        state.orderTime = reservedTimes;

        state.startTimeList = sortStartTimeList(defaultTimes, reservedTimes);

        state.startBufferList = sortStartTimeList(defaultTimes, reservedTimes);
        state.pmData = sortArrs(defaultTimes, reservedTimes);
      } else {
        state.startTimeList = sortStartTimeList(defaultTimes, []);
        state.startBufferList = sortStartTimeList(defaultTimes, []);
        state.pmData = defaultTimes;
      }
    },

    SET_START_TIME: (state, data) => {
      state.startTime = data;
      state.endTime = {};

      const activeValueIndex = state.startTimeList.findIndex(
        (item) => item.time === data.time
      );

      const arr = [];

      for (
        let index = activeValueIndex + 1;
        index < state.startTimeList.length;
        index++
      ) {
        if (state.startTimeList[index].free) {
          arr.push(state.startTimeList[index].time);
        } else {
          break;
        }
      }

      state.pmData = arr;
    },

    SET_END_DATA: (state, data) => {
      state.endTime = data;
    },
  },
};
